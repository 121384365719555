<template>
  <el-input-number
    class="inputNum"
    :min="bookedCount"
    :max="max"
    :step="1"
    step-strictly
    size="small"
  >
  </el-input-number>
</template>
<script>
export default {
  name: "icon-button",
  props: {
    max: Number,
    bookedCount: Number,
  },
  data() {
    return {
      value: 1,
    };
  },
};
</script>
<style>
.inputNum .el-input__inner {
  border: none;
}
.inputNum .el-input-number__decrease,
.inputNum .el-input-number__increase {
  width: 20px !important;
  height: 20px !important;
  border: 1px solid #ccc;
  border-radius: 50%;
  background: none;
  top: 7px;
  /* line-height: 20px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputNum .el-input-number--small {
  line-height: 18px;
}
.el-icon-minus,
.el-icon-plus {
  font-size: 20px;
}
.inputNum .el-input-number .el-input__inner {
  color: #009d7d;
}
.inputNum .el-input-number__increase:hover,
.inputNum .el-input-number__decrease:hover {
  color: #fff;
  background: #009d7d;
  border: none;
}
</style>